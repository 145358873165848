import React, { useEffect } from 'react';
import styled from 'styled-components';
import { GlobalStyles } from '../../../../styles/globalStyles';
import { colors, sizes } from '../../../../styles/theme';
import LogoSvg from '../../../../assets/svgs/logo.svg';

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
  transform: translate(-50%, -50%);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10vh;
`;

const Logo = styled(LogoSvg)`
  transform: rotate(15deg);
`;

const Paragraph = styled.p`
  text-align: center;
  max-width: 400px;
  font-family: 'Gilroy';
`;

const Title = styled.h2`
  text-align: center;
  font-family: 'GilroySemiBold';
  font-style: normal;
`;

export const getTokenFromUrl = (url: string) => {
  const params = new URLSearchParams(url)
  return params.get('consentId')
}

const OpenBanking: React.FC = () => {
  let screenWidth: number = 0;
  if (typeof window !== 'undefined' && window.innerWidth) {
    screenWidth = window.innerWidth;
  }
  const isMobile = screenWidth < sizes.ipadPro;

  useEffect(() => {
    if (isMobile && window.location.search) {
      const token = getTokenFromUrl(window.location.search)
      window.location.replace(`teal18://OpenBankingConsent/${token}`);
    }
  }, []);

  return (
    <Wrapper>
      <GlobalStyles />
      <Content>
        <Logo />
        {isMobile ? (
          <>
            <Title id="openbanking-consent-mobile-version">You need to install the Bó app</Title>
            <Paragraph>
              You need to confirm this Open Banking request in the Bó app.
              Please install the app and try again.
            </Paragraph>
          </>
        ) :  (
          <>
            <Title id="openbanking-consent-desktop-version">Please use your mobile</Title>
            <Paragraph>
              You need to confirm this Open Banking request in your Bó app.
              Please try again on your mobile device.
            </Paragraph>
          </>
        )}
      </Content>
    </Wrapper>
  );
};

export default OpenBanking;
